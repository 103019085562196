//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  http_session_verbal_group_list,
  http_session_verbal_add_group_info,
  http_session_verbal_update_group_info,
  http_session_verbal_delete_group_info,
  http_session_verbal_content_list,
  http_session_verbal_add_content_info,
  http_session_verbal_update_content_info,
  http_session_verbal_delete_content_info
} from "@/api/session.js"

export default {
  name: "Index",
  data() {
    return {
      dialogGroupVisible: false,
      dialogContentVisible: false,
      dialogUpdateGroupVisible: false, //是否显示更新分组内容
      dialogContentTableVisible: false, //内容表格管理
      dialogContentUpdateVisible: false, //修改回复内容
      quick_group_name: "",
      quick_group_info: {},
      quick_group_index: -1,
      group_list: [],
      group_index: -1,
      content_list: [],
      content_name: "",
      content_info: {},
      content_index: -1,
      tableGroupData: [],
      tableGroupCount: 0,
      tableContentData: [],
      tableContentCount: 0,
      verbal_group_page: 1,
      verbal_group_page_size: 20,
      page: 1,
      page_size: 20,
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.request_http_session_verbal_group_list();
      this.request_http_session_verbal_group_table();
    })
  },
  methods: {
    /**
     * 加载分组的快捷回复内容
     */
    request_http_session_verbal_group_list() {
      http_session_verbal_group_list().then(res => {
        if (res.code == 0) {
          let data = res.data.data;
          this.group_list = data;
          if (data.length > 0) {
            this.on_select_group(data[0], 0);
          }
        }
      })
    },

    request_http_session_verbal_group_table() {
      let data = {page: this.verbal_group_page, page_size: this.verbal_group_page_size};
      http_session_verbal_group_list(data).then(res => {
        if (res.code == 0) {
          this.tableGroupData = res.data.data;
          this.tableGroupCount = res.data.count;
        }
      })
    },

    on_select_group(row, index) {
      this.group_index = index;
      this.quick_group_info = row;
      http_session_verbal_content_list(row).then(res => {
        if (res.code == 0) {
          this.content_list = res.data.data;
        }
      })
    },

    on_send_content_message(data) {
      this.$store.commit("set_send_message_content_text", data);
    },

    /**
     * 增加分组
     */
    addTrickGroup() {
      this.dialogGroupVisible = true;
    },

    on_add_group_data() {
      if (this.quick_group_name.length > 0) {
        http_session_verbal_add_group_info({quick_group_name: this.quick_group_name}).then(res => {
          if (res.code == 0) {
            this.tableGroupData.unshift(res.data);
            this.tableGroupCount++;
            this.quick_group_name = "";
          }
        })
      }
    },

    on_content_namager(row, index) {
      console.log(row);
      console.log(index);
      this.request_http_session_verbal_content_list(row);
      this.dialogContentTableVisible = true;
      this.quick_group_info = row;
    },

    on_update_group_data(row, index) {
      console.log(row);
      console.log(index);
      this.dialogUpdateGroupVisible = true;
      this.quick_group_info = row;
      this.quick_group_index = index;
    },

    on_delete_group_data(row, index) {
      console.log(row);
      console.log(index);
      http_session_verbal_delete_group_info(row).then(res => {
        if (res.code == 0) {
          this.tableGroupData.splice(index, 1);
        }
      })
    },

    on_submit_update_group_data() {
      http_session_verbal_update_group_info(this.quick_group_info).then(res => {
        if (res.code == 0) {
          this.$set(this.tableGroupData, this.quick_group_index, this.quick_group_info);
          this.dialogUpdateGroupVisible = false;
        }
      })
    },

    request_http_session_verbal_content_list(row) {
      http_session_verbal_content_list({id: row.id, page: this.page, page_size: this.page_size}).then(res => {
        if (res.code == 0) {
          this.tableContentData = res.data.data;
          this.tableContentCount = res.data.count;
        }
      })
    },


    /**
     * 增加回复内容
     */
    on_add_content_data(x) {
      let group_id = this.quick_group_info.id;
      let data = {content: this.content_name, group_id: group_id};
      if (this.content_name.length <= 0) {
        this.$message.error("快捷回复内容不能为空");
        return false;
      }
      console.log(data);
      http_session_verbal_add_content_info(data).then(res => {
        if (res.code == 0) {
          if (x == 1) {
            this.tableContentData.unshift(res.data);
          } else {
            this.dialogContentVisible = false;
            this.content_list.unshift(res.data);
          }
          this.content_name = "";
        }
      })
    },


    /**
     * 显示修改界面
     */
    on_update_content_data(row, index) {
      this.dialogContentUpdateVisible = true;
      this.content_info = JSON.parse(JSON.stringify(row));
      this.content_index = index;
    },


    /**
     * 修改内容
     */
    on_submit_update_content_data() {
      if (this.content_info.content.length <= 0) {
        this.$message.error("快捷回复内容不能为空");
        return false;
      }
      http_session_verbal_update_content_info({
        content: this.content_info.content,
        qcid: this.content_info.qcid
      }).then(res => {
        if (res.code == 0) {
          this.dialogContentUpdateVisible = false;
          this.$set(this.tableContentData, this.content_index, res.data);
        }
      })
    },


    on_delete_content_data(row, index) {

      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        http_session_verbal_delete_content_info(row).then(res => {
          if (res.code == 0) {
            this.tableContentData.splice(index, 1);
          }
        })
      }).catch(() => {
      });

    },
    ChangePageGroup(page) {
      this.verbal_group_page = page;
      this.request_http_session_verbal_group_table();
    },
    onChangePageContent(page) {
      this.page = page;
      this.request_http_session_verbal_content_list(this.quick_group_info);
    },

    /**
     * 增加话术内容
     */
    addTrickContent() {
      this.dialogContentVisible = true;
    }

  }
}
