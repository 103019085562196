//
//
//
//
//
//
//
//
//
//
//
//
//

import Merchant from "@/components/Session/module/merchant/Index.vue"
import Client from "@/components/Session/module/client/Index.vue"
import Chat from "@/components/Session/module/chat/Index.vue"
import Verbal from "@/components/Session/module/verbal/Index.vue"



export default {
  name: "Index",
  components: {
    Merchant, Client, Chat, Verbal
  },
  data() {
    return {
      messageTextarea: ""
    }
  }
}
