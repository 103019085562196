//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {http_session_account_list} from "@/api/session.js"

export default {
  name: "Index",
  data() {
    return {
      page: 1,
      page_size: 300,
      count: 0,
      account_list: [],
      active_index: -1,
    }
  },

  mounted() {
    this.$nextTick(function () {
      this.request_http_session_account_list();
    });
  },

  methods: {
    /**
     * 加载抖音账号列表
     */
    request_http_session_account_list() {
      http_session_account_list({page: this.page, page_size: this.page_size}).then(res => {
        if (res.code == 0) {
          let data = res.data.data;
          this.count = res.data.count;
          this.$store.commit('set_session_merchant_list', res.data.data);
          if (this.active_index == -1 && data.length > 0) {
            this.on_select_account(data[0], 0);
            this.active_index = 0;
          }
        }
      })
    },
    on_select_account(row, index) {
      let merchant_list = this.$store.state.session_merchant_list;
      let info_merchant = JSON.parse(JSON.stringify(row));
      info_merchant.unread = 0;
      this.$set(merchant_list, index, info_merchant);
      this.active_index = index;
      this.$store.commit('set_session_merchant_item', row);
    }
  }

}
