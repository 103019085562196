//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {http_session_userinfo_list,http_session_userinfo_clear_unread} from "@/api/session.js"

export default {
  name: "Index",
  data() {
    return {
      active_merchant_item: null,
      // client_list: [],
      count: 0,
      active_index: -1,
    }
  },
  mounted() {

  },

  methods: {

    /**
     * 加载对话用户列表
     */
    request_http_session_userinfo_list(init=true) {
      let _this = this;
      console.log("请求私信用户")
      http_session_userinfo_list({ghid: this.active_merchant_item.open_id}).then(res => {
        console.log("请求 成功")
        if (res.code == 0) {
          console.log("请求设置用户列表")
          _this.$store.commit("set_session_user_list",res.data.data);
          let data = res.data.data;
          // this.client_list = data;
          _this.count = res.data.count;
          if (init) {
            _this.on_select_client(data[0], 0);
          }

        }
      })
    },

    /**
     * 选择对话用户
     * @param row
     * @param index
     */
    on_select_client(row, index) {

      this.active_index = index;
      if(row.unread>0){
        http_session_userinfo_clear_unread({user_id:row.id}).then(res=>{
          console.log(res);
        })
        row.unread = 0;
      }
      this.$store.commit("set_session_user_item", row);
    }

  },

  watch: {
    /**
     * 监听抖音授权账号被选择的时候，进行切换
     * @param newValue
     * @param oldValue
     */
    '$store.state.session_merchant_item': function (newValue, oldValue) {
      // console.log("监控抖音账号的变化");
      if (newValue != null) {
        this.active_merchant_item = newValue;
        // console.log("新的：" + newValue.nickname);
        // console.log("旧的：" + oldValue);
        console.log(oldValue);
        this.request_http_session_userinfo_list();
      }
    }
  }
}
