//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MessageTextLeft from "./MessageTextLeft"
import MessageTextRight from "./MessageTextRight"
import MessageImageRight from "./MessageImageRight"
import MessageSystemText from "./MessageSystemText"
import {
  http_session_chatlog_list,
  http_session_userinfo_info,
  http_session_userinfo_toggle,
  http_session_userinfo_update,
  http_session_send_text
} from "@/api/session.js";

export default {
  name: "Index",
  components: {
    MessageTextLeft, MessageTextRight, MessageImageRight, MessageSystemText
  },
  data() {
    return {
      messageTextarea: "",
      fileList: [],
      dialogVisible: false,
      userinfo: {
        name: "",
        telephone: "",
        wechat: "",
        remark: "",
        is_online: true,
      },
      auto_chat: true,
      // upload_url: "http://admin.dy.com",
      upload_url: "https://dy.yskcrm.com",
      headers: {},
      upload_data: {},
      file_name: "msg_file",
      loading:false,
      // message_list: []
    }
  },
  mounted() {
    this.$nextTick(function () {

      let container = this.$el.querySelector("#chatbody");
      container.scrollTop = container.scrollHeight;
      this.upload_url = this.upload_url + '/session/send/image';
      this.headers.authorization = localStorage.getItem("authorization");


    })
  },
  methods: {

    beforeUpload(file) {
      let ghid = this.$store.state.session_merchant_item.open_id;
      let open_id = this.$store.state.session_user_item.open_id;
      this.upload_data.open_id = open_id;
      this.upload_data.ghid = ghid;
      console.log(this.upload_data);
      console.log(file);
      console.log("文件上传之前的准备");
      this.loading = true;
    },
    onUploadSuccess(response, file, fileList){
      console.log("文件上传成功");
      console.log(response);
      console.log(file);
      console.log(fileList);
      this.loading = false;
      let _this = this;
      if(response.code==0){
        this.$store.commit("push_session_chat_content", response.data);
      }else{
        this.$message.error(response.message);
      }
      setTimeout(function () {
        let container = _this.$el.querySelector("#chatbody");
        container.scrollTop = container.scrollHeight;
      }, 1200);
    },

    sendMessage() {
      let _this = this;
      let ghid = this.$store.state.session_merchant_item.open_id;
      let open_id = this.$store.state.session_user_item.open_id;
      let message = {
        message: this.messageTextarea, ghid, open_id
      };

      this.messageTextarea = "";
      http_session_send_text(message).then(res => {
        if (res.code == 0) {
          this.$store.commit("push_session_chat_content", res.data);
        }else{
          this.$message.error(res.message);
        }
        setTimeout(function () {
          let container = _this.$el.querySelector("#chatbody");
          container.scrollTop = container.scrollHeight;
        }, 1200);
      })

    },

    /**
     * 回车发送数据
     */
    handleKeyCode(event) {
      if (event.keyCode == 13) {
        if (!event.metaKey) {
          event.preventDefault();
          this.sendMessage();
        }
      }
    },


    handleChange() {

    },
    isShowUpdateInfo() {
      this.dialogVisible = true;
    },
    onSubmitInfo() {
      this.dialogVisible = false;
    },


    /**
     * 加载聊天日志
     */
    request_http_session_chatlog_list() {
      let _this = this;
      let ghid = this.$store.state.session_merchant_item.open_id;
      let open_id = this.$store.state.session_user_item.open_id;
      http_session_chatlog_list({page: 1, page_size: 20, ghid: ghid, open_id: open_id}).then(res => {
        if (res.code == 0) {
          // this.message_list = res.data;
          this.$store.commit("set_session_chat_content", res.data);
        }
        setTimeout(function () {
          let container = _this.$el.querySelector("#chatbody");
          container.scrollTop = container.scrollHeight;
        }, 1200);
      })
    },

    /**
     * 加载用户详情
     */
    request_http_session_userinfo_info() {
      let ghid = this.$store.state.session_merchant_item.open_id;
      let open_id = this.$store.state.session_user_item.open_id;
      http_session_userinfo_info({ghid: ghid, open_id: open_id}).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.userinfo = res.data;
        }
      })
    },

    /**
     * 切换是否在线状态
     */
    on_change_is_online() {
      let ghid = this.$store.state.session_merchant_item.open_id;
      let open_id = this.$store.state.session_user_item.open_id;
      let is_online = this.userinfo.is_online ? 1 : -1;
      http_session_userinfo_toggle({ghid: ghid, open_id: open_id, is_online: is_online}).then(res => {
        if (res.code == 0) {
          this.$message.success("切换成功");
        }
      })
    },


    /**
     * 提交用户信息
     */
    on_submit_info() {
      let ghid = this.$store.state.session_merchant_item.open_id;
      let open_id = this.$store.state.session_user_item.open_id;
      let data = this.userinfo;
      data.ghid = ghid;
      data.open_id = open_id;
      http_session_userinfo_update(data).then(res => {
        if (res.code == 0) {
          // this.$message.success("提交成功");
          this.dialogVisible = false;
        }
      })
    }


  },

  watch: {
    '$store.state.send_message_content_text': function (newValue, oldValue) {
      console.log("文本内容发生变化了");
      console.log(newValue);
      console.log(oldValue);
      this.messageTextarea = newValue.content;
    },
    '$store.state.session_user_item': function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      if (newValue != null) {
        this.request_http_session_chatlog_list();
        this.request_http_session_userinfo_info();
      }

    },
    '$store.state.session_chat_new_message': function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      let _this = this;
      setTimeout(function () {
        let container = _this.$el.querySelector("#chatbody");
        container.scrollTop = container.scrollHeight;
      }, 1200);
    }
  }


}
